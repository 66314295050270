export default class PageSize
{
    width: number = 0
    height: number = 0
    hasChanged: boolean = false

    set(width: number, height: number): void
    {
        this.width = width
        this.height = height

        this.hasChanged = true
    }
}
