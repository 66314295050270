<script setup lang="ts">
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import MainPage from "@/views/MainPage.vue";
</script>

<script lang="ts">
import {defineComponent} from "vue"

export default defineComponent({
    name: 'App',
})
</script>

<template>
    <MainPage/>
</template>

<style lang="scss">
div {
    position: relative;
}

body {
    background: black;
    margin: 0;
    padding: 0;
    font-size: 13px;

    //font-family: Storopia, Arial !important;
    font-family: Storopia, serif !important;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.textShadow {
    text-shadow:
        0.1em 0 5px rgba(0, 0, 0, 0.99),
        0 0.1em 5px rgba(0, 0, 0, 0.99),
        -0.1em 0 5px rgba(0, 0, 0, 0.99),
        0 -0.1em 5px rgba(0, 0, 0, 0.99),
        -0.1em -0.1em 5px rgba(0, 0, 0, 0.99),
        -0.1em 0.1em 5px rgba(0, 0, 0, 0.99),
        0.1em -0.1em 5px rgba(0, 0, 0, 0.99),
        0.1em 0.1em 5px rgba(0, 0, 0, 0.99);
}

@font-face {
    font-family: Storopia;
    src:    url('/public/font/Storopia.woff2') format("woff2"),
            url('/public/font/Storopia.ttf')  format('truetype'),
            url('/public/font/Storopia.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>
