<script setup lang="ts">
import SpaceScene from "@/components/scene/SpaceScene.vue";
</script>

<script lang="ts">
import {defineComponent} from 'vue'
import {GraphicsManager} from "@/classes/graphics/GraphicsManager";
import store from "@/store";

export default defineComponent({
    name: 'MainPage',

    data(): {
        isRunning: boolean
        isLoaded: boolean
        loadCheckInterval: number
    } {
        return {
            isRunning: false,
            isLoaded: false,
            loadCheckInterval: 0,
        }
    },

    beforeMount(): void
    {
        const textures = [
            `/img/background/bg_1.webp`,
            `/img/background/10200.jpg`,
            `/img/space/misc/lightning.png`,
        ]

        const graphicsManager: GraphicsManager = store.state.graphicsManager
        textures.forEach((url: string) => graphicsManager.getTexture(url))
    },

    methods: {
        onMouseMove(event: MouseEvent): void
        {
            store.state.cursor.set(
                event.clientX,
                event.clientY,
            )
        },

        onResize(): void
        {
            store.state.pageSize.set(
                window.innerWidth,
                window.innerHeight,
            )
        },

        start(): void
        {
            this.isRunning = true
            store.state.audio.play()
        }
    },

    mounted() {
        window.addEventListener('resize', () => {this.onResize()})

        this.onResize()

        this.loadCheckInterval = setInterval(() => {
            this.isLoaded = store.state.graphicsManager.dataStorage.isLoaded() && store.state.audio.isLoaded

            if (this.isLoaded) {
                store.state.graphicsManager.initStages()
                clearInterval(this.loadCheckInterval)
                return
            }
        }, 100)

        store.state.graphicsManager.refs = this.$refs
    },
})
</script>

<template>
    <div
        class="wrapper"
        @mousemove="onMouseMove"
        v-if="isLoaded"
    >
        <Transition name="fade" mode=out-in>
            <div class="button noselect" v-if="!isRunning" @click="start">
                Поехали!
            </div>

            <SpaceScene v-else/>
        </Transition>
    </div>

    <div
        class="loading"
        v-else
    >
        <span><s>Сиськи</s></span>
        <span>Загрузка</span>
    </div>
</template>

<style scoped lang="scss">
.labels {
    display: none;
}

.loading {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;

    flex-direction: column;
    gap: 1;

    span {
        font-size: 40px;
        color: gray;
    }
}

.wrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;

    .button {
        font-size: 40px;
        color: #b4b4b4;
        padding: 50px;
        cursor: pointer;

        &:hover {
            color: white;
        }

        &:active {
            color: white;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    //transition: opacity 2s ease;
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
