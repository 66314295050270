import {AbstractStage} from "@/classes/graphics/stage/StageInterface";
import * as THREE from "three";
import {randInt} from "three/src/math/MathUtils";
import SpaceSphereStage from "@/classes/graphics/stage/SpaceSphereStage";

export default class HyperTunnelStage extends AbstractStage
{
    private layers: THREE.Mesh[] = []
    private newLayers: THREE.Mesh[] = []

    private speed: number = 30
    private passedDistance: number = 0
    private layerNumber: number = 0

    private geometry: THREE.PlaneGeometry = new THREE.PlaneGeometry(400, 400)

    private DURATION: number = 1120
    // private DURATION: number = 210

    private startTick: number = 0

    animate(tick: number): void
    {
        const currentTick = tick - this.startTick

        if (currentTick > this.DURATION) {
            this.destroy()
        }

        if (currentTick > this.DURATION - 500) {
            if (this.speed > 7) {
                this.speed *= 0.992
            }
        }

        this.passedDistance += this.speed

        if (currentTick < this.DURATION - 230) {
            while (this.passedDistance > 50 * this.layerNumber) {
                this.addLayer()
            }
        }

        if (currentTick === this.DURATION - 100) {
            const stage = (new SpaceSphereStage(this.graphicsManager)).init()
            this.graphicsManager.stages[stage.id] = stage
        }

        this.animateLayers()
    }

    init(): HyperTunnelStage
    {
        this.startTick = this.graphicsManager.tick

        return this
    }

    private animateLayers(): void
    {
        this.layers.forEach((layer: THREE.Mesh, index: number): void => {
            layer.rotation.z += layer.userData.rotationSpeed
            layer.position.z += this.speed

            if (layer.position.z >= this.graphicsManager.camera.position.z) {
                this.graphicsManager.scene.remove(layer)
                this.layers.splice(index, 1)
            }
        })

        this.newLayers.forEach((layer: THREE.Mesh, index: number): void => {
            const material: THREE.Material = layer.material as THREE.Material
            material.opacity += 0.02

            if (material.opacity > 0.18) {
                this.newLayers.splice(index, 1)
            }
        })
    }

    private destroy(): void
    {
        const scene: THREE.Scene = this.graphicsManager.scene

        this.layers.forEach((layer: THREE.Mesh) => scene.remove(layer))

        delete this.graphicsManager.stages[this.id]
    }

    private addLayer(): void
    {
        this.layerNumber++

        const texture: THREE.Texture = this.graphicsManager.getTexture(`/img/background/bg_1.webp`)
        const mesh = new THREE.Mesh(
            this.geometry,
            new THREE.MeshBasicMaterial({
                color: 0xFFFFFF,
                map: texture,
                side: THREE.FrontSide,
                transparent: true,
                opacity: 0.0,
                blending: THREE.AdditiveBlending,
            })
        )

        mesh.rotation.z = Math.PI / 360 * randInt(1, 360)
        mesh.position.z = -1500

        mesh.userData = {
            rotationSpeed: 0.001 * randInt(-10, 10)
        }

        this.graphicsManager.scene.add(mesh)
        this.layers.push(mesh)
        this.newLayers.push(mesh)

    }
}
