import {AbstractStage} from "@/classes/graphics/stage/StageInterface";
import * as THREE from "three";
import StarCloudStage from "@/classes/graphics/stage/StarCloudStage";
import GalaxyStage from "@/classes/graphics/stage/GalaxyStage";

export default class SpaceSphereStage extends AbstractStage
{
    private skybox!: THREE.Group
    private nebulasGroup!: THREE.Group

    private speed: number = 30
    private rotationSpeed: number = 0.0005

    private startTick: number = 0
    private FADE_START_TICK: number = 950
    private GALAXY_START_TICK: number = 850
    // private FADE_START_TICK: number = 200
    // private LIGHTNING_START_TICK: number = 200

    private nebulasOpacityNormalized: boolean = false

    animate(tick: number): void
    {
        if (!this.skybox) {
            return
        }

        const material: THREE.Material = (this.skybox.children[0].children[0].children[0].children[0].children[0] as THREE.Mesh).material as THREE.Material

        if (tick - this.startTick < this.FADE_START_TICK) {
            if (material.opacity < 0.8) {
                material.opacity += 0.03
            }

            if (this.graphicsManager.dataStorage.nebulasOpacity < 0.4) {
                this.graphicsManager.dataStorage.nebulasOpacity += 0.005
                Object.values(this.graphicsManager.dataStorage.nebulasMaterials).forEach((material: THREE.Material): number => material.opacity += 0.005)
            }
        }

        if (this.skybox.position.z < -1200 && this.speed > 0) {
            this.speed -= 0.5
            this.skybox.position.z += this.speed
            this.nebulasGroup.position.z += this.speed
        }

        this.skybox.rotation.x += this.rotationSpeed

        this.nebulasGroup.position.y += 0.35
        this.nebulasGroup.children.forEach((mesh: THREE.Object3D) => mesh.rotation.z += mesh.userData.rotationSpeed)

        if (tick - this.startTick > this.FADE_START_TICK) {
            this.rotationSpeed += 0.00005
            material.opacity -= 0.0033

            Object.values(this.graphicsManager.dataStorage.nebulasMaterials).forEach((material: THREE.Material): number => material.opacity -= 0.0033)

            if (material.opacity <= 0) {
                this.destroy()
            }
            // this.destroy()
        }

        // if (tick - this.startTick === 666 * this.LIGHTNING_START_TICK) {
        //     const stage = (new LightningStage(this.graphicsManager)).init()
        //     this.graphicsManager.stages[stage.id] = stage
        // }

        if (tick - this.startTick === this.GALAXY_START_TICK) {
            const stage = (new GalaxyStage(this.graphicsManager)).init()
            this.graphicsManager.stages[stage.id] = stage
        }

    }

    private destroy(): void
    {
        this.graphicsManager.scene.remove(this.skybox)
        this.graphicsManager.scene.remove(this.nebulasGroup)

        delete this.graphicsManager.stages[this.id]
    }

    init(): SpaceSphereStage
    {
        this.startTick = this.graphicsManager.tick

        this.skybox = this.graphicsManager.dataStorage.spaceSphere
        this.skybox.position.z = -2200
        this.skybox.rotation.y = Math.PI
        this.skybox.scale.set(2200, 2200, 2200)
        // this.skybox.position.z = -2300
        this.graphicsManager.scene.add(this.skybox)

        this.nebulasGroup = this.graphicsManager.dataStorage.nebulasGroup
        // this.nebulasGroup.position.z = -500
        // this.graphicsManager.scene.add(this.nebulasGroup)

        const stage = (new StarCloudStage(this.graphicsManager)).init()
        this.graphicsManager.stages[stage.id] = stage

        return this
    }
}
