import * as THREE from "three";
import {StageInterface} from "@/classes/graphics/stage/StageInterface";
import {DataStorage} from "@/classes/graphics/DataStorage";
import {State} from "@/store/state/State";
import {CSS3DRenderer} from "three/examples/jsm/renderers/CSS3DRenderer";
import AsteroidStage from "@/classes/graphics/stage/AsteroidStage";

class GraphicsManager
{
    _state!: State
    refs: Record<string, unknown> = {}

    renderer: THREE.WebGLRenderer
    cssRenderer: CSS3DRenderer
    camera: THREE.PerspectiveCamera
    scene: THREE.Scene
    clock: THREE.Clock

    stages: { [key: string]: StageInterface } = {}
    tick: number = 0

    dataStorage: DataStorage
    textureLoader: THREE.TextureLoader
    textures: TextureContainer = {}

    set state(state: State)
    {
        this._state = state
    }

    get state(): State
    {
        return this._state
    }

    constructor() {
        const renderer: THREE.WebGLRenderer = new THREE.WebGLRenderer({
            alpha: true,
            antialias: true,
            powerPreference: "high-performance",
        })
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( window.innerWidth, window.innerHeight );
        renderer.outputColorSpace = THREE.SRGBColorSpace;

        const cssRenderer: CSS3DRenderer = new CSS3DRenderer()
        cssRenderer.setSize( window.innerWidth, window.innerHeight );
        cssRenderer.domElement.style.position = 'absolute'
        cssRenderer.domElement.style.top = '0'
        cssRenderer.domElement.style.pointerEvents = 'none'
        cssRenderer.domElement.classList.add('cssRendererElement')

        const camera: THREE.PerspectiveCamera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 4500 );
        camera.position.set( 0, 0, 0 );
        camera.lookAt( 0, 0, 0 );

        this.renderer = renderer
        this.cssRenderer = cssRenderer
        this.camera = camera
        this.scene = new THREE.Scene()
        this.clock = new THREE.Clock()
        this.textureLoader = new THREE.TextureLoader()
        this.dataStorage = new DataStorage(this)
    }

    initStages(): void
    {
        // const cameraTiltStage = new CameraTiltStage(this, this.state.cursor, this.state.pageSize)
        // this.stages[cameraTiltStage.id] = cameraTiltStage

        // const stage = (new HyperTunnelStage(this)).init()
        // this.stages[stage.id] = stage

        const asteroidStage = (new AsteroidStage(this)).init()
        this.stages[asteroidStage.id] = asteroidStage

        // const sunStage = (new SunStage(this)).init()
        // this.stages[sunStage.id] = sunStage
        //
        // this.stages = {
        //     [sunStage.id]: sunStage,
        // }

        // const spaceSphereStage = (new SpaceSphereStage(this)).init()
        // this.stages = {
        //     [spaceSphereStage.id]: spaceSphereStage,
        // }

        // stage = (new SpaceSphereStage(this)).init()
        // stage = (new StarCloudStage(this)).init()
    }

    render(): void
    {
        // console.log(this.tick)
        this.tick++

        // this.processors.forEach((processor: ProcessorInterface): void => processor.process())
        Object.values(this.stages).forEach((stage: StageInterface): void => stage.animate(this.tick))

        this.renderer.render(
            this.scene,
            this.camera,
        )

        this.cssRenderer.render(
            this.scene,
            this.camera,
        )
    }

    getTexture(imagePath: string, reloadTexture: boolean = false): THREE.Texture
    {
        if (!this.textures[imagePath] || reloadTexture) {
            this.textures[imagePath] = this.textureLoader.load(imagePath)
            this.textures[imagePath].colorSpace = THREE.SRGBColorSpace
        }

        return this.textures[imagePath]
    }
}

type TextureContainer = {
    [key: string]: THREE.Texture
}

export {
    GraphicsManager,
}
