<script setup lang="ts">
import {default as templateStore} from "@/store";
</script>

<script lang="ts">
import {defineComponent, toRaw} from "vue"
import store from "@/store";
import {GraphicsManager} from "@/classes/graphics/GraphicsManager";

export default defineComponent({
    name: 'SpaceScene',

    data(): {
        delta: number
        interval: number
        labels: { [key: string]: boolean }

        labelTicks: { [key: string]: {
            start: number
            end: number
        } }
    } {
        return {
            delta: 0,
            interval: 1 / 60,
            // interval: 1 / 10,

            labels: {
                label_1: false,
                label_2: false,
                label_3: false,
                label_4: false,
                label_5: false,
                label_6: false,
                label_7: false,
                label_8: false,
                label_9: false,
            },

            labelTicks: {
                label_1: {
                    start: 1000,
                    end: 1500,
                },
                label_2: {
                    start: 1550,
                    end: 2000,
                },


                label_3: {
                    start: 2200,
                    end: 2500,
                },
                label_4: {
                    start: 2550,
                    end: 2850,
                },
                label_5: {
                    start: 2900,
                    end: 3200,
                },


                label_6: {
                    start: 3500,
                    end: 3800,
                },
                label_7: {
                    start: 4100,
                    end: 4400,
                },


                label_8: {
                    start: 5000,
                    end: 5300,
                },
                label_9: {
                    start: 5400,
                    end: 7200,
                },
            },
        }
    },

    mounted(): void
    {
        const div = document.getElementById('spaceCanvasWrapper') as HTMLDivElement

        div.style.width = `100%`
        div.style.height = `100%`

        div.appendChild(store.state.graphicsManager.renderer.domElement)
        div.appendChild(store.state.graphicsManager.cssRenderer.domElement)

        this.render()
    },

    methods: {
        render(): void
        {
            requestAnimationFrame(this.render)

            const graphicsManager: GraphicsManager = toRaw(store.state.graphicsManager)

            this.delta += graphicsManager.clock.getDelta()

            if (this.delta > this.interval) {
                graphicsManager.render()

                this.delta = this.delta % this.interval

                if (graphicsManager.tick % 10 === 0) {
                    Object.entries(this.labelTicks).forEach((entry: [key: string, value: {start: number, end: number}]): void => {
                        const [label, data] = entry

                        this.labels[label] = graphicsManager.tick >= data.start && graphicsManager.tick <= data.end
                    })
                }
            }
        },
    },
})
</script>

<template>
    <div
        id="spaceCanvasWrapper"
        class="noselect"
        @contextmenu.prevent
    />

    <div class="labels">
        <TransitionGroup name="list" tag="span" mode=out-in>
            <span class="label" v-if="labels.label_1">Жизненный путь может казаться тернистым</span>
            <span class="label" v-if="labels.label_2">но он ведёт к звёздам</span>

            <span class="label" v-if="labels.label_3">Вселенная велика</span>
            <span class="label" v-if="labels.label_4">она лежит перед тобой</span>
            <span class="label" v-if="labels.label_5">и ты в ней не одинока</span>

            <span class="label" v-if="labels.label_6">Какой бы тёмной ни казалась ночь...</span>
            <span class="label" v-if="labels.label_7">...в итоге всегда восходит солнце</span>

            <span class="label" v-if="labels.label_8">Свети, как сверхновая, что ярче галактик</span>
            <span class="label" v-if="labels.label_9">С днём рождения, Наташа!</span>
        </TransitionGroup>
    </div>

    <input
        type="range"
        class="form-range"
        min="0"
        max="1"
        step="0.01"
        v-model="templateStore.state.audio.audio.volume"
        id="customRange3"
    >
</template>

<style scoped lang="scss">
.form-range {
    z-index: 10000;
    position: absolute;
    bottom: 10px;
    right: 0;
    width: 100px;
    scale: 0.8;
    opacity: 0.3;

    &:hover {
        opacity: 1;
    }
}
.list-move,
.list-enter-active,
.list-leave-active {
    transition: all 0.2s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
}

.labels {
    position: absolute;
    z-index: 2000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
        &.label {
            color: white;
            font-size: 40px;

            text-shadow:
                0.1em 0 5px rgba(0, 0, 0, 0.99),
                0 0.1em 5px rgba(0, 0, 0, 0.99),
                -0.1em 0 5px rgba(0, 0, 0, 0.99),
                0 -0.1em 5px rgba(0, 0, 0, 0.99),
                -0.1em -0.1em 5px rgba(0, 0, 0, 0.99),
                -0.1em 0.1em 5px rgba(0, 0, 0, 0.99),
                0.1em -0.1em 5px rgba(0, 0, 0, 0.99),
                0.1em 0.1em 5px rgba(0, 0, 0, 0.99);
        }
    }
}

#spaceCanvasWrapper {
    position: fixed;
    background: black;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
