import * as THREE from "three";
import {GraphicsManager} from "@/classes/graphics/GraphicsManager";
import {randInt} from "three/src/math/MathUtils";
import {GLTF, GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {FontLoader} from "three/examples/jsm/loaders/FontLoader";
import {TextGeometry} from "three/examples/jsm/geometries/TextGeometry";
import {TextureAnimator} from "@/classes/graphics/TextureAnimator";

class DataStorage
{
    graphicsManager: GraphicsManager
    gltfLoader: GLTFLoader

    spaceSphere!: THREE.Group
    starCloud!: THREE.Points
    starCloud2!: THREE.Points
    nebulasGroup!: THREE.Group
    nebulasMaterials: { [key: number]: THREE.Material } = {}
    nebulaGeometry: THREE.PlaneGeometry = new THREE.PlaneGeometry(1, 1)
    nebulasOpacity: number = 0

    galaxy_1!: THREE.Mesh
    asteroid!: THREE.Object3D
    blackHole!: THREE.Object3D
    blackHoleExplosion!: THREE.Object3D
    blackHoleExplosionTextureAnimator!: TextureAnimator
    sunMesh!: THREE.Object3D
    sunRings!: THREE.Object3D[]
    sunGlow!: THREE.Mesh

    labels: {[key: string]: THREE.Mesh} = {}
    loadedLabels: boolean = false

    constructor(graphicsManager: GraphicsManager) {
        this.graphicsManager = graphicsManager
        this.gltfLoader = new GLTFLoader()

        this.initSpaceSphere()
        this.initNebulas()
        this.initStarCloud()
        this.initGalaxy1()
        this.initAsteroid()
        this.initLabels()
        this.initBlackHole()
        this.initSun()
    }

    isLoaded(): boolean
    {
        return (this.asteroid && this.spaceSphere && this.starCloud && this.galaxy_1 && this.blackHole && this.loadedLabels)
    }

    private initSun(): void
    {
        const sunMesh = new THREE.Mesh(
            new THREE.SphereGeometry(1, 512, 512),
            new THREE.MeshBasicMaterial({
                color: 0xFFFFFF,
                blending: THREE.AdditiveBlending,
                map: this.graphicsManager.getTexture(`/img/sun_1.jpg`),
            })
        )

        const sunMesh2 = new THREE.Mesh(
            new THREE.SphereGeometry(1.005, 512, 512),
            new THREE.MeshBasicMaterial({
                color: 0xFFFFFF,
                blending: THREE.AdditiveBlending,
                transparent: true,
                opacity: 0.6,
                map: this.graphicsManager.getTexture(`/img/sun_1.jpg`),
            })
        )
        sunMesh2.rotation.x = Math.PI

        sunMesh.add(sunMesh2)








        const ringGeometry: THREE.PlaneGeometry = new THREE.PlaneGeometry(1, 1)
        const ringMaterial = new THREE.MeshBasicMaterial({
            color: 0xFFFFFF,
            transparent: true,
            opacity: 0.3,
            blending: THREE.AdditiveBlending,
            map: this.graphicsManager.textureLoader.load(`/img/gasring.png`),
            depthTest: false,
        })

        const rings: THREE.Object3D[] = []
        for (let i = 1; i < 5; i++) {
            const ring = new THREE.Mesh(
                ringGeometry,
                ringMaterial,
            )

            ring.scale.set(2.7, 2.7, 1)
            ring.userData = {
                rotationSpeed: 0.0005 * i * Math.pow(-1, i),
            }

            rings.push(ring)
        }
        this.sunRings = rings






        const glowGeometry: THREE.PlaneGeometry = new THREE.PlaneGeometry(1, 1)
        const glowMaterial = new THREE.MeshBasicMaterial({
            color: 0xFFFFFF,
            transparent: true,
            opacity: 0.0,
            blending: THREE.AdditiveBlending,
            map: this.graphicsManager.textureLoader.load(`/img/sunglow.png`),
            depthTest: false,
        })
        const glow = new THREE.Mesh(
            glowGeometry,
            glowMaterial,
        )
        glow.scale.set(7, 7, 1)
        this.sunGlow = glow

        sunMesh.userData = {
            layer1: sunMesh2,
        }

        this.sunMesh = sunMesh























        const material = new THREE.PointsMaterial({
            color: 0xFFFFFF,
            size: 0.05,
            map: this.graphicsManager.getTexture(`/img/dust/pointCloud.webp`),
            transparent: true,
            depthTest: false,
            opacity: 0.0,
        })

        const geometry: THREE.BufferGeometry = new THREE.BufferGeometry();
        const vertices: number[] = [];
        for (let j: number = 0; j < 1000; j++) {
            const x: number = randInt(-1000, 1000) / 300,
                y: number = randInt(-2000, 500) / 300,
                z: number = randInt(-2000, -600) / 300

            vertices.push(x, y, z);
        }

        geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(vertices), 3));

        this.starCloud2 = new THREE.Points(geometry, material)
    }

    private initLabels(): void
    {
        const labels = {
            label_1: '02.02.2024',
        }

        const loader = new FontLoader()
        loader.load(
            `/font/Storopia.json`,
            (font) => {
                Object.entries(labels).forEach((entry: [key: string, text: string]): void => {
                    const [key, text] = entry

                    this.labels[key] = new THREE.Mesh(
                        new TextGeometry(
                            text,
                            {
                                font: font,
                                size: 4,
                                height: 0.01,
                                curveSegments: 12,
                                bevelEnabled: false,
                            }
                        ),
                        new THREE.MeshBasicMaterial({
                            color: 0xFFFFFF,
                            transparent: true,
                            opacity: 1.0,
                        })
                    )
                })

                this.loadedLabels = true
            }
        )
    }

    private initAsteroid(): void
    {
        this.gltfLoader.load(
            `/3d/asteroid/scene.gltf`,
            (gltf: GLTF): void => {
                this.asteroid = gltf.scene
            }
        )
    }

    private initBlackHole(): void
    {
        this.gltfLoader.load(
            `/3d/black_hole_2/scene.gltf`,
            (gltf: GLTF): void => {
                this.blackHole = gltf.scene

                const ringGeometry: THREE.PlaneGeometry = new THREE.PlaneGeometry(1, 1)
                const ringMaterial = new THREE.MeshBasicMaterial({
                    color: 0xFFFFFF,
                    transparent: true,
                    opacity: 0.1,
                    blending: THREE.AdditiveBlending,
                    side: THREE.DoubleSide,
                    map: this.graphicsManager.textureLoader.load(`/img/gasring.png`),
                    depthTest: false,
                })

                const rings: THREE.Mesh[] = []
                for (let i = 0; i < 0; i++) {
                    const ring = new THREE.Mesh(
                        ringGeometry,
                        ringMaterial
                    )

                    const scale: number = 2.7 + (i / 2) / 1 + Math.random() / 100
                    ring.scale.set(scale, scale, 1)
                    ring.position.y = 0.01 + 0.01 * i
                    ring.rotation.x = Math.PI / 2
                    ring.userData = {
                        rotationSpeed: 0.0001 * i * Math.pow(1, i) + Math.random() / 100,
                    }

                    rings.push(ring)
                    this.blackHole.add(ring)
                }

                this.blackHole.userData = {
                    rings: rings,
                }


                // const glow = new THREE.Mesh(
                //     new THREE.PlaneGeometry(1, 1),
                //     new THREE.MeshBasicMaterial({
                //         color: 0xFFFFFF,
                //         transparent: true,
                //         opacity: 0.8,
                //         blending: THREE.AdditiveBlending,
                //         side: THREE.DoubleSide,
                //         map: this.graphicsManager.textureLoader.load(`/img/sunglow.png`)
                //     })
                // )
                // glow.scale.set(12, 12, 1)
                // glow.position.y = 0.011
                // glow.rotation.x = Math.PI / 2
                // this.blackHole.add(glow)


                const bhRing: THREE.Mesh = this.blackHole.children[0].children[0].children[0].children[0].children[1].children[0] as THREE.Mesh
                this.blackHole.children[0].children[0].children[0].children[0].children[1].remove(bhRing)
                // const bhRingMaterial: THREE.Material = bhRing.material as THREE.Material
                // bhRingMaterial.blending = THREE.AdditiveBlending
                // bhRingMaterial.opacity = 0.0
                // bhRingMaterial.transparent = true



                const texture = this.graphicsManager.getTexture('/img/big_explosion_4x4_16_256.webp')
                // const texture = this.graphicsManager.getTexture('/img/sunglow.png')
                this.blackHoleExplosionTextureAnimator = new TextureAnimator(
                    texture,
                    4,
                    4,
                    16,
                    3,
                    1,
                )

                this.blackHoleExplosion = new THREE.Mesh(
                    new THREE.PlaneGeometry(1, 1),
                    new THREE.MeshBasicMaterial({
                        color: 0xFFFFFF,
                        transparent: true,
                        map: texture,
                    })
                )
            }
        )
    }

    private initGalaxy1(): void
    {
        this.gltfLoader.load(
            `/3d/galaxy_2/scene.gltf`,
            (gltf: GLTF): void => {
                const mesh = gltf.scene.children[0].children[0].children[0].children[0].children[0] as THREE.Mesh
                this.galaxy_1 = mesh
                const materialMesh = mesh.children[0] as THREE.Mesh
                ;(materialMesh.material as THREE.Material).opacity = 0
                ;(materialMesh.material as THREE.Material).transparent = true
                ;(materialMesh.material as THREE.Material).blending = THREE.AdditiveBlending
                ;(materialMesh.material as THREE.Material).depthTest = false
            }
        )
    }

    private initNebulas(): void
    {
        const group: THREE.Group = new THREE.Group()

        for (let i = 0; i < 250; i++) {
            const key = randInt(1, 4)
            let material = this.nebulasMaterials[key]
            if (!material) {
                material = this.nebulasMaterials[key] = new THREE.MeshBasicMaterial({
                    color: 0xFFFFFF,
                    map: this.graphicsManager.getTexture(`/img/nebula/${key}.png`),
                    transparent: true,
                    opacity: 0.0,
                    blending: THREE.AdditiveBlending,
                })
            }

            const nebula = new THREE.Mesh(
                this.nebulaGeometry,
                material,
            )

            nebula.userData = {
                rotationSpeed: 0.00005 * randInt(-10, 10)
            }

            const scale = randInt(200, 400)
            nebula.scale.set(scale, scale, scale)
            nebula.rotation.z = randInt(0, 360) * Math.PI / 180
            nebula.position.set(
                randInt(-800, 800),
                randInt(-1000, 600),
                randInt(-1600, -700),
            )

            group.add(nebula)
        }

        this.nebulasGroup = group

        this.nebulasGroup.position.z = -500
        this.graphicsManager.scene.add(this.nebulasGroup)
    }

    private initSpaceSphere(): void
    {
        this.gltfLoader.load(
            `/3d/skybox/scene.gltf`,
            (gltf: GLTF): void => {
                this.spaceSphere = gltf.scene
                const mesh = this.spaceSphere.children[0].children[0].children[0].children[0].children[0] as THREE.Mesh
                ;(mesh.material as THREE.Material).opacity = 0
                ;(mesh.material as THREE.Material).transparent = true
            }
        )

        // return
        //
        // const mesh = new THREE.Mesh(
        //     // new THREE.SphereGeometry(700, 512, 512, Math.PI, Math.PI, Math.PI / 4),
        //     new THREE.SphereGeometry(1000, 256, 256, Math.PI, Math.PI, 0.7),
        //     new THREE.MeshBasicMaterial({
        //         color: 0xFFFFFF,
        //         blending: THREE.AdditiveBlending,
        //         side: THREE.BackSide,
        //         map: this.graphicsManager.getTexture(`/img/background/2000.webp`),
        //         opacity: 0.0,
        //     })
        // )
        //
        // this.spaceSphere = mesh
    }

    private initStarCloud(): void
    {
        const material = new THREE.PointsMaterial({
            color: 0xFFFFFF,
            size: 8,
            map: this.graphicsManager.getTexture(`/img/dust/pointCloud.webp`),
            transparent: true,
            depthTest: false,
        })

        const geometry: THREE.BufferGeometry = new THREE.BufferGeometry();
        const vertices: number[] = [];
        for (let j: number = 0; j < 4000; j++) {
            const x: number = randInt(-500, 500),
                y: number = randInt(-1500, 500),
                z: number = randInt(-500, -1000)

            vertices.push(x, y, z);
        }

        geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(vertices), 3));

        this.starCloud = new THREE.Points(geometry, material)
    }

}

export {
    DataStorage,
}
