import {AbstractStage} from "@/classes/graphics/stage/StageInterface";
import * as THREE from "three";
import HyperTunnelStage from "@/classes/graphics/stage/HyperTunnelStage";

export default class AsteroidStage extends AbstractStage
{
    private asteroid!: THREE.Object3D
    private light!: THREE.PointLight
    private label!: THREE.Mesh

    private startTick: number = 0

    private HYPER_TUNNEL_STAGE_TICK = 960
    private LABEL_APPEARANCE_TICK = 350
    private LABEL_START_FADE_TICK = 850
    // private LABEL_APPEARANCE_TICK = 5

    animate(tick: number): void
    {
        this.asteroid.position.x += 0.34
        this.asteroid.position.y += 0.05

        this.asteroid.rotation.x += 0.001
        this.asteroid.rotation.y += 0.004

        const currentTick: number = tick - this.startTick

        if (currentTick === this.HYPER_TUNNEL_STAGE_TICK) {
            const stage = (new HyperTunnelStage(this.graphicsManager)).init()
            this.graphicsManager.stages[stage.id] = stage

            this.destroy()
        }

        if (currentTick === this.LABEL_APPEARANCE_TICK) {
            this.label = this.graphicsManager.dataStorage.labels.label_1
            this.label.position.set(-15, -2, -150)
            this.graphicsManager.scene.add(this.label)
        }

        if (currentTick > this.LABEL_START_FADE_TICK) {
            const material: THREE.Material = this.label.material as THREE.Material
            material.opacity -= 0.01


        }
    }

    private destroy(): void
    {
        this.graphicsManager.scene.remove(this.asteroid)
        this.graphicsManager.scene.remove(this.light)
        this.graphicsManager.scene.remove(this.label)

        delete this.graphicsManager.stages[this.id]
    }

    init(): AsteroidStage
    {
        this.startTick = this.graphicsManager.tick

        this.asteroid = this.graphicsManager.dataStorage.asteroid
        this.asteroid.scale.set(20, 20, 20)
        this.asteroid.position.set(
            -120,
            -13,
            -100,
        )
        this.graphicsManager.scene.add(this.asteroid)

        this.light = new THREE.PointLight(
            0xFFFFFF,
            10,
            10000,
            0,
        )
        this.light.position.set(-300, 0, 200)
        this.graphicsManager.scene.add(this.light)

        return this
    }
}
