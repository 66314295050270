import * as THREE from 'three'

type AnimatorContainer = {
    [key: string]: TextureAnimator
}

class TextureAnimator {
    private readonly texture: THREE.Texture
    private readonly tilesHorizontal: number
    private readonly tilesVertical: number
    private readonly numberOfTiles: number
    private readonly tileDisplayDuration: number

    // how long has the current image been displayed?
    private currentDisplayTime: number = 0

    // which image is currently being displayed?
    private currentTile: number = 0
    private currentReplay: number = 1
    private replayCount: number = 0
    mustBeRemoved: boolean = false
    parentMesh: THREE.Object3D | null = null

    constructor(
        texture: THREE.Texture,
        tilesHoriz: number,
        tilesVert: number,
        numTiles: number,
        tileDispDuration: number,
        replayCount: number = 0,
        parentMesh: THREE.Object3D | null = null,
    ) {
        // note: texture passed by reference, will be updated by the update function.
        this.texture = texture
        this.tilesHorizontal = tilesHoriz
        this.tilesVertical = tilesVert

        // how many images does this spritesheet contain?
        //  usually equals tilesHoriz * tilesVert, but not necessarily,
        //  if there are blank tiles at the bottom of the spritesheet.
        this.numberOfTiles = numTiles
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.repeat.set(1 / this.tilesHorizontal, 1 / this.tilesVertical)

        // how long should each image be displayed?
        this.tileDisplayDuration = tileDispDuration

        // Count of replays (0 - infinite)
        this.replayCount = replayCount
        this.parentMesh = parentMesh

        this.updateTextureOffset()
    }

    update(milliSec: number): void
    {
        this.currentDisplayTime += milliSec

        while (this.currentDisplayTime > this.tileDisplayDuration) {
            this.currentDisplayTime -= this.tileDisplayDuration

            this.currentTile++
            if (this.currentTile === this.numberOfTiles) {
                this.currentTile = 0

                this.currentReplay++

                if (this.replayCount !== 0 && this.currentReplay > this.replayCount) {
                    this.mustBeRemoved = true
                }
            }

            this.updateTextureOffset()
        }
    }

    private updateTextureOffset(): void
    {
        const currentColumn = this.currentTile % this.tilesHorizontal
        const currentRow = this.tilesVertical - Math.floor(this.currentTile / this.tilesHorizontal) - 1

        this.texture.offset.x = currentColumn / this.tilesHorizontal

        this.texture.offset.y = currentRow / this.tilesVertical
    }
}

export {
    AnimatorContainer,
    TextureAnimator,
}