import {AbstractStage} from "@/classes/graphics/stage/StageInterface";
import * as THREE from "three";
import {TextureAnimator} from "@/classes/graphics/TextureAnimator";
import SunStage from "@/classes/graphics/stage/SunStage";

export default class BlackHoleStage extends AbstractStage
{
    private blackHole!: THREE.Object3D
    private explosion!: THREE.Object3D
    private textureAnimator!: TextureAnimator
    private speed: number = 0.3

    private startTick: number = 0
    private EXPLOSION_TICK = 195

    private SUN_START_TICK = 700

    animate(tick: number): void
    {
        if (!this.blackHole) {
            return
        }

        const currentTick = tick - this.startTick

        this.blackHole.userData.rings.forEach((ring: THREE.Mesh): void => {
            ring.rotation.z += ring.userData.rotationSpeed
        })

        this.blackHole.rotation.z += 0.001
        this.blackHole.rotation.x -= 0.005
        // this.blackHole.rotation.x -= 0.006
        // this.blackHole.rotation.z += 0.001
        this.blackHole.position.z -= this.speed
        this.speed += 0.05

        if (currentTick >= this.EXPLOSION_TICK) {
            this.textureAnimator.update(1)

            if (currentTick === this.EXPLOSION_TICK) {
                this.graphicsManager.scene.add(this.explosion)
                this.graphicsManager.scene.remove(this.blackHole)
            }

            if (currentTick === this.EXPLOSION_TICK + 48) {
                this.graphicsManager.scene.remove(this.explosion)
            }
        }

        if (currentTick === this.SUN_START_TICK) {
            const sunStage = (new SunStage(this.graphicsManager)).init()
            this.graphicsManager.stages[sunStage.id] = sunStage

            delete this.graphicsManager.stages[this.id]
        }


        // this.galaxy.rotation.z -= 0.01
        // this.galaxy.position.y += 10

        // const material: THREE.Material = (this.galaxy.children[0] as THREE.Mesh).material as THREE.Material
        // if (material.opacity < 0.4) {
        //     material.opacity += 0.002
        // }
    }

    init(): BlackHoleStage
    {
        this.startTick = this.graphicsManager.tick

        this.blackHole = this.graphicsManager.dataStorage.blackHole
        this.graphicsManager.scene.add(this.blackHole)

        this.blackHole.position.set(0, 0, -16)
        this.blackHole.rotation.x = 3 * Math.PI / 2

        this.blackHole.scale.set(20, 20, 20)



        this.explosion = this.graphicsManager.dataStorage.blackHoleExplosion
        this.explosion.position.z = -10
        this.explosion.scale.set(3, 3, 0)

        this.textureAnimator = this.graphicsManager.dataStorage.blackHoleExplosionTextureAnimator

        return this
    }
}
