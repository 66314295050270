export default class Cursor
{
    x: number = 0
    y: number = 0
    hasMoved: boolean = false

    set(x: number, y: number): void
    {
        this.x = x
        this.y = y

        this.hasMoved = true
    }
}
