import {AbstractStage} from "@/classes/graphics/stage/StageInterface";
import * as THREE from "three";

export default class GalaxyStage extends AbstractStage
{
    private galaxy!: THREE.Mesh

    animate(): void
    {
        if (!this.galaxy) {
            return
        }

        this.galaxy.rotation.z -= 0.01
        this.galaxy.position.y += 10

        const material: THREE.Material = (this.galaxy.children[0] as THREE.Mesh).material as THREE.Material
        if (material.opacity < 0.4) {
            material.opacity += 0.002
        }
    }

    init(): GalaxyStage
    {
        this.galaxy = this.graphicsManager.dataStorage.galaxy_1
        this.graphicsManager.scene.add(this.galaxy)

        this.galaxy.position.set(0, -1500, -1000)
        this.galaxy.rotation.x = - Math.PI / 8
        this.galaxy.rotation.y = - Math.PI / 8

        this.galaxy.scale.set(10, 10, 10)

        const material: THREE.Material = (this.galaxy.children[0] as THREE.Mesh).material as THREE.Material
        material.depthTest = false

        return this
    }
}
