import {AbstractStage} from "@/classes/graphics/stage/StageInterface";
import {GraphicsManager} from "@/classes/graphics/GraphicsManager";
import * as THREE from "three";
import {randInt} from "three/src/math/MathUtils";
import BlackHoleStage from "@/classes/graphics/stage/BlackHoleStage";

export default class LightningStage extends AbstractStage
{
    private LIGHTNING_GEOMETRY: THREE.PlaneGeometry = new THREE.PlaneGeometry(1, 1)
    private lightnings: {[key: string]: THREE.Mesh} = {}

    private LIGHTNING_STAGE_DURATION: number = 400
    private startTick: number = 0

    animate(tick: number): void
    {
        const currentTick = tick - this.startTick

        if (currentTick < this.LIGHTNING_STAGE_DURATION - 20) {
            if (randInt(1, 25) === 1) {
                this.createLightning()
            }
        }

        if (currentTick === this.LIGHTNING_STAGE_DURATION) {
            const blackHoleStage = (new BlackHoleStage(this.graphicsManager)).init()
            this.graphicsManager.stages[blackHoleStage.id] = blackHoleStage

            this.destroy()
            return
        }

        Object.values(this.lightnings).forEach((lightning: THREE.Object3D): void => {
            const material: THREE.Material = (lightning as THREE.Mesh).material as THREE.Material

            material.opacity += lightning.userData.opacityDelta
            if (material.opacity >= 0.6) {
                lightning.userData.opacityDelta = -0.2
            }

            if (material.opacity <= 0) {
                this.graphicsManager.scene.remove(lightning)
                material.dispose()
                delete this.lightnings[lightning.id]
            }
        })
    }

    destroy(): void
    {
        Object.values(this.lightnings).forEach((lightning: THREE.Object3D): void => {
            this.graphicsManager.scene.remove(lightning)
        })

        delete this.graphicsManager.stages[this.id]
    }

    init(): LightningStage
    {
        this.startTick = this.graphicsManager.tick
        return this
    }

    createLightning(): void
    {
        const graphicsManager: GraphicsManager = this.graphicsManager

        const lightning: THREE.Mesh = new THREE.Mesh(
            this.LIGHTNING_GEOMETRY,
            new THREE.MeshBasicMaterial({
                color: 0xFFFFFF,
                transparent: true,
                map: graphicsManager.getTexture(`/img/lightning.png`),
                opacity: 0.0,
                blending: THREE.AdditiveBlending,
                depthTest: false,
            }),
        )

        const scale: number = randInt(100, 300)
        lightning.scale.set(scale, scale, 1)

        lightning.position.set(
            randInt(-600, 600),
            randInt(-600, 600),
            randInt(-1500, -500),
        )

        lightning.rotation.z = randInt(0, 628) / 100
        lightning.userData.opacityDelta = 0.2

        graphicsManager.scene.add(lightning)
        this.lightnings[lightning.id] = lightning
    }
}
