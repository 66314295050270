import {GraphicsManager} from "@/classes/graphics/GraphicsManager";
import {generateUUID} from "three/src/math/MathUtils";

interface StageInterface
{
    readonly id: string

    animate(tick: number): void
    init(): StageInterface
}

abstract class AbstractStage implements StageInterface
{
    readonly id: string
    graphicsManager: GraphicsManager

    constructor(graphicsManager: GraphicsManager) {
        this.id = generateUUID()
        this.graphicsManager = graphicsManager

        // this.init()
    }

    init(): StageInterface
    {
        return this
    }

    abstract animate(tick: number): void
}

export {
    StageInterface,
    AbstractStage,
}
