import { createStore } from 'vuex'
import {State} from "@/store/state/State";

export default createStore({
    state: new State(),

    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
})
