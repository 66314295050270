import {AbstractStage} from "@/classes/graphics/stage/StageInterface";
import * as THREE from "three";
import {toRaw} from "vue";

export default class SunStage extends AbstractStage
{
    private skybox!: THREE.Group
    private starCloud!: THREE.Points

    private sun!: THREE.Object3D
    private glow!: THREE.Mesh
    private rings!: THREE.Object3D[]

    private startTick: number = 0

    private position: THREE.Vector3 = new THREE.Vector3(0, -4, -5.2)

    private FADE_START_TICK = 940
    // private LABEL_APPEARANCE_TICK = 5

    animate(tick: number): void
    {
        const currentTick: number = tick - this.startTick

        const material: THREE.Material = (this.skybox.children[0].children[0].children[0].children[0].children[0] as THREE.Mesh).material as THREE.Material
        const starCloudMaterial: THREE.Material = this.starCloud.material as THREE.Material
        const glowMaterial: THREE.Material = this.glow.material as THREE.Material

        this.skybox.rotation.x += 0.001

        this.starCloud.position.y += 0.004
        this.starCloud.position.z -= 0.002

        if (currentTick < this.FADE_START_TICK) {
            material.opacity = Math.min(1.0, material.opacity + 0.003)
            starCloudMaterial.opacity = Math.min(1.0, starCloudMaterial.opacity + 0.02)
            glowMaterial.opacity = Math.min(0.35, glowMaterial.opacity + 0.002)

            this.position.y += 0.015
            this.position.z -= 0.01

            this.sun.rotation.z += 0.002
            const layer1: THREE.Mesh = this.sun.userData.layer1
            layer1.rotation.z -= 0.002

            this.sun.position.copy(this.position)
            this.glow.position.copy(this.position)

            this.rings.forEach((ring: THREE.Object3D): void => {
                // ring.lookAt(this.graphicsManager.camera.position)
                ring.rotation.z += 2 * ring.userData.rotationSpeed
                ring.position.copy(this.position)
            })
        }

        if (currentTick >= this.FADE_START_TICK) {
            this.graphicsManager.scene.remove(this.sun)
            this.graphicsManager.scene.remove(this.glow)
            this.graphicsManager.scene.remove(...this.rings)

            material.opacity -= 0.005
            starCloudMaterial.opacity -= 0.005

            if (material.opacity <= 0) {
                this.destroy()
            }
        }
    }

    private destroy(): void
    {
        this.graphicsManager.scene.remove(this.sun)
        this.graphicsManager.scene.remove(this.glow)
        this.graphicsManager.scene.remove(...this.rings)

        this.graphicsManager.scene.remove(this.skybox)
        this.graphicsManager.scene.remove(this.starCloud)

        delete this.graphicsManager.stages[this.id]
    }

    init(): SunStage
    {
        console.warn(this.graphicsManager.scene.children)
        this.startTick = this.graphicsManager.tick

        this.rings = toRaw(this.graphicsManager.dataStorage.sunRings)
        this.rings.forEach((ring: THREE.Object3D): void => {
            this.graphicsManager.scene.add(ring)
        })

        this.glow = toRaw(this.graphicsManager.dataStorage.sunGlow)
        this.graphicsManager.scene.add(this.glow)

        this.sun = toRaw(this.graphicsManager.dataStorage.sunMesh)
        this.graphicsManager.scene.add(this.sun)


        this.starCloud = toRaw(this.graphicsManager.dataStorage.starCloud2)
        this.graphicsManager.scene.add(this.starCloud)








        this.skybox = this.graphicsManager.dataStorage.spaceSphere
        this.skybox.position.z = -29
        this.skybox.rotation.y = Math.PI
        this.skybox.scale.set(30, 30, 30)
        // this.skybox.position.z = -2300
        this.graphicsManager.scene.add(this.skybox)
        const material: THREE.Material = (this.skybox.children[0].children[0].children[0].children[0].children[0] as THREE.Mesh).material as THREE.Material
        material.opacity = 0


        return this
    }
}
