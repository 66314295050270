import {GraphicsManager} from "@/classes/graphics/GraphicsManager";
import Cursor from "@/classes/Cursor";
import PageSize from "@/classes/PageSize";

class AudioState
{
    audio: HTMLAudioElement
    isLoaded: boolean = false

    constructor() {
        this.audio = new Audio()
        this.audio.src = `/music/two_stars.mp3`
        this.audio.volume = 0.1
        // this.audio.volume = 0.0
        this.audio.muted = false

        this.audio.oncanplay = (): void => {
            this.isLoaded = true
        }
    }

    play(): void
    {
        this.audio.play()
    }
}

class State
{
    graphicsManager: GraphicsManager
    cursor: Cursor
    pageSize: PageSize
    audio: AudioState

    constructor() {
        this.graphicsManager = new GraphicsManager()
        this.cursor = new Cursor()
        this.pageSize = new PageSize()
        this.audio = new AudioState()

        this.init()
    }

    private init(): void
    {
        this.graphicsManager.state = this
    }
}

export {
    State,
}
