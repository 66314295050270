import {AbstractStage} from "@/classes/graphics/stage/StageInterface";
import * as THREE from "three";
import LightningStage from "@/classes/graphics/stage/LightningStage";

export default class StarCloudStage extends AbstractStage
{
    private cloud!: THREE.Points
    private speed: number = 0.6

    private startTick: number = 0
    private FADE_START_TICK: number = 1000

    animate(tick: number): void
    {
        if (!this.cloud) {
            return
        }

        const currentTick: number = tick - this.startTick

        const material: THREE.Material = this.cloud.material as THREE.Material

        if (currentTick < this.FADE_START_TICK) {
            if (material.opacity < 1) {
                material.opacity += 0.02
            }
        } else {
            this.speed += 0.09
            material.opacity -= 0.005

            if (material.opacity <= 0) {
                this.destroy()
            }
        }

        this.cloud.position.y += this.speed

        if (currentTick === this.FADE_START_TICK) {
            const stage = (new LightningStage(this.graphicsManager)).init()
            this.graphicsManager.stages[stage.id] = stage
        }
    }

    private destroy(): void
    {
        this.graphicsManager.scene.remove(this.cloud)

        delete this.graphicsManager.stages[this.id]
    }

    init(): StarCloudStage
    {
        this.startTick = this.graphicsManager.tick

        const starCloud = this.graphicsManager.dataStorage.starCloud
        this.cloud = starCloud

        const material: THREE.Material = this.cloud.material as THREE.Material
        material.opacity = 0
        material.transparent = true

        this.graphicsManager.scene.add(starCloud)

        return this
    }
}
